import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import SubHeader from '../components/subheader';
import { graphql, Link} from 'gatsby'
import SideBar from '../components/sidebar/index';
import blogAvatar from '../images/blog-default.jpg'

const TagTemplate = ({data}) => {
    return (
        <Layout>
            <SEO title={data.strapiTags.name} />
            <SubHeader title={data.strapiTags.name}/>
            <div id="blog-single">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-xs-12">
                    <div className="blog-post">
                      {
                        data.strapiTags.posts.map((item, index)=>{
                          return (
                            <div className="col-md-4 col-lg-4 col-xs-12 category-item" key={`key_${index}`} style={{display: 'inlineBlock'}}>
                              <div className="services-item wow fadeInRight blog">
                                <div className="post-thumb">
                                  <Link to={`/Posts_${item.id}`}>
                                    <img src={item.feature_image ? item.feature_image.childImageSharp.fluid.src : blogAvatar} className="feature-image img-thumbnail" height="120px"/>
                                  </Link>
                                </div>
                                <div className="services-content blog">
                                  <h3><Link to={`/Posts_${item.id}`}>{`${item.post_title.substring(0, 17)}...`}</Link></h3>
                                  <p>{`${item.post_excerpt.substring(0, 100)}...`}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <SideBar/>
                </div>
              </div>
            </div>
        </Layout>
    )
}

export default TagTemplate;

export const query = graphql`
  query TagTemplate($id: String!) {
    strapiTags(id: { eq: $id }) {
      name
      posts {
        id
        post_title
        post_excerpt
        feature_image {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
` 